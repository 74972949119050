<template lang="pug">
.om-onboarding-qualification.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(:show-progress="false")
  .container(:style="'margin-top: 4.75rem'")
    template(v-if="showOtherScreen")
      .row.justify-content-center
        .col-4
          wizard-title {{ $t('welcomePage.business') }}
        .col-4
          om-textarea(
            v-model="otherBusiness"
            :placeholder="$t('welcomePage.businessType')"
            :rows="3"
          )
            template(#error v-if="$v.otherBusiness.$error && !$v.otherBusiness.required")
              span {{ $t('requiredField') }}
      .row.justify-content-center.mt-5
        .col-4
          om-button.ml-auto(ghost @click="$router.go(-1)") {{ $t('back') }}
        .col-4
          om-button(primary @click="send" :disabled="sending") {{ $t('next') }}
    .row(v-else)
      .d-none.d-lg-flex.col-lg-1
      .col-3
        om-monks.mb-4.mb-md-0(monk="hi-user2")
      .col-9.col-lg-7.pb-5
        wizard-title.text-center.text-md-left(:style="'margin-bottom: 1.6rem'") {{ $t('welcomePage.title', { businessName, prefix }) }}
        affiliate-onboarding(v-if="false")
        om-select-cards.w-100(v-else v-model="business" :options="businessOptions")
      .d-none.d-lg-flex.col-lg-1
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import SEND_USER_QUALIFICATIONS from '@/graphql/SendUserQualifications.gql';
  import AffiliateOnboarding from '@/components/Affiliate/Onboarding/AffiliateOnboarding.vue';
  import WizardTop from '@/components/Wizard/Top.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import { required } from 'vuelidate/lib/validators';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import navigationMixin from '../accountSetup-navigation';

  export default {
    components: { AffiliateOnboarding, WizardTop, WizardTitle },
    mixins: [navigationMixin],

    data() {
      return {
        business: null,
        otherBusiness: '',
        showOtherScreen: false,
        sending: false,
      };
    },

    validations: {
      otherBusiness: {
        required,
      },
    },

    computed: {
      ...mapGetters(['hasActiveShopify', 'isAffiliate', 'isOnboardingAiEnabled']),
      ...mapState(['account']),
      prefix() {
        return this.account.businessName ? 'a(z)' : 'a';
      },
      businessNameFallback() {
        return this.$i18n.locale === 'hu' ? 'vállalkozásod' : 'your business';
      },
      businessName() {
        return this.account.businessName || this.businessNameFallback;
      },
      businessOptions() {
        const obj = this.$t('welcomePage.businessOptions');

        return Object.keys(obj).map((k) => ({
          icon: k === 'saas' ? 'service' : k,
          key: obj[k],
          value: k,
        }));
      },
    },

    watch: {
      async business() {
        if (!this.business) {
          return;
        }

        if (this.business === 'other') {
          this.$router.push({ query: { other: 1 } });
          return;
        }

        await this.send();
      },
    },

    async created() {
      this.showOtherScreen = this.$route.query.other;
      if (this.showOtherScreen) {
        this.business = 'other';
      }
    },

    methods: {
      async send() {
        if (this.showOtherScreen) {
          this.$v.$touch();
          if (this.$v.$invalid) {
            return;
          }
        }

        this.sending = true;

        if (window.OptiMonk?.Visitor?.createAdapter) {
          const optiMonkAdapter = window.OptiMonk.Visitor.createAdapter();
          optiMonkAdapter.attr('business_type', this.business);
        }

        const {
          data: { success },
        } = await this.$apollo.mutate({
          mutation: SEND_USER_QUALIFICATIONS,
          variables: {
            business: this.business,
            otherBusiness: this.otherBusiness,
            stage: '',
            whyUs: '',
            onboardingVersion: 'wizard0510',
          },
        });

        if (success) {
          this.$router.push({ name: accountSetupRouteNames.AGENCY_BRANCHING });
        }
        this.sending = false;
      },
    },
  };
</script>
<style lang="sass">
  .om-onboarding-qualification
    .brand-select-cards
      @media screen and (min-width: 1200px)
        padding-right: 11rem
</style>
